<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-39.mp3"),
      },
      timeStampList: [9.7,11.5, 19, 21, 23.3, 28.7,30.1, 34],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "z,c,s",
          },
          {
            id: 2,
            chineseWords: "zh,ch,sh,r",
          },
          {
            id: 3,
            chineseWords: "chá（茶）",
          },
          {
            id: 4,
            chineseWords: "zhuō（桌）",
          },
          {
            id: 5,
            chineseWords: "shū（书）",
          },
          {
            id: 6,
            chineseWords: "z, c, s",
          },
          {
            id: 7,
            chineseWords: "zh,ch,sh,r",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
